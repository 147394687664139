import styles from './AppointmentsOverview.module.scss';
import AppointmentList from 'components/AppointmentList/AppointmentList';
import PatientContentLayout from 'components/PatientContentLayout/PatientContentLayout';
import ClientHeader from '../../components/ClientHeader/ClientHeader';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';

const AppointmentsOverview = () => {
  const { clientProfileData, isClientProfileLoading } = useGetClientProfileDetails();

  return (
    <PatientContentLayout
      className={styles.container}
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      }}
    >
      <div className={styles.contentWrapper}>
        <ClientHeader withMenu />
        <div className={styles.content}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>Your Appointment Overview</div>
          </div>
          <AppointmentList
            isPatientProfileLoading={isClientProfileLoading}
            clientProfileTimeZone={clientProfileData?.timeZone}
          />
        </div>
      </div>
    </PatientContentLayout>
  );
};

export default AppointmentsOverview;
