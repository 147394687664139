import { notification } from 'antd';
import Button from 'components/v2/Button/Button';
import { Invoice } from 'interfaces/Invoice/Invoice';
import { LegacyRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getMarkInvoicePaid, getUnmarkInvoicePaid } from 'utils/http/invoice';
import { postStripeCheckoutSession } from 'utils/http/stripe';
import queryString from 'query-string';

import BasicDetails from './components/BasicDetails/BasicDetails';
import InvoiceTotal from './components/InvoiceTotal/InvoiceTotal';
import ItemsList from './components/ItemsList/ItemsList';
import MedicareDetails from './components/MedicareDetails/MedicareDetails';
import PaymentMethods from './components/PaymentMethods/PaymentMethods';
import styles from './InvoiceView.module.scss';
import { shouldShowStripePayment } from 'components/InvoiceViewWithTemplate/helpers/shouldShowStripePayment';
import { CLIENT_BRAND, ClientBrand } from 'interfaces/ClientBrand';

interface InvoiceViewProps {
  invoice: Invoice;
  isStripeActive: boolean;
  refetchInvoice: () => void;
  pdfRef?: LegacyRef<HTMLDivElement>;
}

const InvoiceView = ({ invoice, pdfRef, isStripeActive, refetchInvoice }: InvoiceViewProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { toMarkPaid }: { toMarkPaid?: string } = queryString.parse(location.search);

  const [isMarking, setIsMarking] = useState(false);
  const [isPayingOnline, setIsPayingOnline] = useState(false);

  const {
    _id,
    clientRecord,
    clinician,
    description,
    discount,
    dueDate,
    invoiceId,
    issueDate,
    items,
    paymentMethods,
    practice,
    taxRate,
    status,
    group,
    medicare,
    invoiceAmount
  } = invoice;

  const showStripePayment = isStripeActive && shouldShowStripePayment({ clientBrand: CLIENT_BRAND, status });

  useEffect(() => {
    const fetchMarkedPaid = async () => {
      if (!['draft', 'confirmPaid', 'closed', 'markedPaid'].includes(status) && toMarkPaid === 'true') {
        try {
          await getMarkInvoicePaid(_id);
          notification.success({ message: `Invoice ${invoiceId} successfully marked as paid` });
          refetchInvoice();
        } catch (err) {
          notification.error({ message: 'Unable to mark/unmark invoice as paid' });
          console.error(err);
        }
      }

      const queryParams = new URLSearchParams(location.search);
      queryParams.delete('toMarkPaid');
      navigate(
        {
          search: queryParams.toString()
        },
        {
          replace: true
        }
      );
    };

    fetchMarkedPaid();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toMarkPaid]);

  const payOnline = async () => {
    setIsPayingOnline(true);
    try {
      const { url } = await (
        await postStripeCheckoutSession(_id, `${window.location.href}?stripePaymentSuccess=1`)
      ).json();
      window.location.href = url;
    } catch (ex) {
      setIsPayingOnline(false);
      notification.error({ message: 'Unable to initiate online checkout session.' });

      console.error(ex);
    }
  };

  const markPaid = async () => {
    setIsMarking(true);
    if (!['draft', 'confirmPaid', 'closed'].includes(status)) {
      try {
        await getMarkInvoicePaid(_id);
        notification.success({ message: `Invoice ${invoiceId} successfully marked as paid` });
        refetchInvoice();
      } catch (err) {
        notification.error({ message: 'Unable to mark invoice as paid' });
        console.error(err);
      } finally {
        setIsMarking(false);
      }
    }
  };

  const unmarkPaid = async () => {
    setIsMarking(true);
    if (!['draft', 'confirmPaid', 'closed'].includes(status)) {
      try {
        await getUnmarkInvoicePaid(_id);
        notification.success({ message: `Invoice ${invoiceId} successfully unmarked as paid` });
        refetchInvoice();
      } catch (err) {
        notification.error({ message: 'Unable to undo action' });
        console.error(err);
      } finally {
        setIsMarking(false);
      }
    }
  };

  const serviceDeliveredDates = items.filter((item) => item.appointmentDate).map((item) => item.appointmentDate);

  return (
    <>
      <div ref={pdfRef} className={styles.container}>
        <BasicDetails
          clientRecord={clientRecord}
          clinician={clinician}
          dueDate={dueDate}
          invoiceId={invoiceId}
          issueDate={issueDate}
          practice={practice}
          group={group}
          serviceDeliveredDates={serviceDeliveredDates}
        />
        {description && (
          <div className={styles.descriptionContainer}>
            <div className={styles.label}>Description of services</div>
            <div className={styles.description}>{description}</div>
          </div>
        )}
        {medicare && <MedicareDetails medicare={medicare} />}
        <ItemsList discount={discount} items={items} taxRate={taxRate} amountChanged={invoice.amountChanged} />
        <InvoiceTotal
          discount={discount}
          items={items}
          taxRate={taxRate}
          status={status}
          invoiceAmount={invoiceAmount}
          amountChanged={invoice.amountChanged}
        />
      </div>
      <div>
        {showStripePayment && (
          <div className={styles.payOnlineContainer}>
            <div className={styles.payOnlineContent}>
              <div className={styles.payOnlineDesc}>
                Use our secure checkout to pay via credit card, debit card, Apple Pay or Google Pay
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  className={styles.payOnlineButton}
                  status={isPayingOnline ? 'active' : ''}
                  onClick={() => payOnline()}
                  disabled={status === 'confirmPaid'}
                >
                  {status === 'confirmPaid' ? 'PAID SECURELY ONLINE' : 'PAY SECURELY ONLINE'}
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className={styles.paymentMethodsMarkContainer}>
          <PaymentMethods paymentMethods={paymentMethods} isStripeActive={isStripeActive} />
          {![ClientBrand.HELM, ClientBrand.SOMEONE_HEALTH].includes(CLIENT_BRAND) && (
            <>
              {status === 'issued' && (
                <div className={styles.ctaContainer}>
                  <Button
                    className={styles.ctaMarkButton}
                    status={isMarking ? 'active' : ''}
                    onClick={() => markPaid()}
                  >
                    MARK AS PAID
                  </Button>
                  <div className={styles.ctaDesc}>
                    Let {clinician?.name || practice.name} know you have paid this invoice
                  </div>
                </div>
              )}
              {status === 'markedPaid' && (
                <div className={styles.ctaContainer}>
                  <Button
                    className={styles.ctaUnmarkButton}
                    status={isMarking ? 'active' : ''}
                    onClick={() => unmarkPaid()}
                  >
                    PAID{' '}
                    <span style={{ position: 'relative', fontSize: '8px', top: '-2px', left: '4px' }}>
                      CLICK TO UNDO
                    </span>
                  </Button>

                  <div className={styles.ctaDesc}>
                    You’ve signaled this invoice is <strong>paid</strong>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default InvoiceView;
