import { useAuth0 } from '@auth0/auth0-react';
import JoinWaitlistForm from 'Select/components/JoinWaitlistForm/JoinWaitlistForm';
import FitFilter from 'Select/components/SelectFilter/FitFilter/FitFilter';
import ListingFilter from 'Select/components/SelectFilter/ListingFilter/ListingFilter';
import SelectFooter from 'Select/components/SelectFooter/SelectFooter';
import { FitDaysList, FitDeliveryList, FitPsychologistGenderList, FitTimesList } from 'Select/interfaces/fitFilter';
import { AgeRangeList, LocationList, MentalHealthList } from 'Select/interfaces/mentalHealth';
import { Modal, Skeleton } from 'antd';
import { listInterface } from 'components/FilterDropdown/FilterDropdown';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BookingRuleType, useFetchClientBookingRule } from 'utils/hooks/clientRecords';
import styles from './PsychologistListing.module.scss';
import { useFetchPractitionerList } from './hooks/getPsychologistList';
import { useSelectRoutesGenerator } from 'Select/utils/path/SelectRoutesGenerator';
import { SelectContactUrl, SelectDefaultTimezone } from 'Select/utils/env/SelectEnv';
import PsychologistList from './components/PsychologistList/PsychologistList';
import SelectHelmetWrapper from 'Select/components/SelectHelmetWrapper/SelectHelmetWrapper';
import SelectHeader from 'Select/components/SelectHeader/SelectHeader';
import SelectContentLayout from 'Select/components/SelectContentLayout/SelectContentLayout';

export interface PsychologistFitFilter {
  gender?: FitPsychologistGenderList;
  day?: FitDaysList;
  time?: FitTimesList;
  deliveryMode?: FitDeliveryList;
}

type LocationQueryFilters = PsychologistFitFilter & { ageRange?: AgeRangeList; location?: LocationList[] };

const TIME_FILTER_START_END_TIME_MAP = {
  [FitTimesList.morning]: {
    startTime: '00:00',
    endTime: '12:00'
  },
  [FitTimesList.afternoon]: {
    startTime: '12:00',
    endTime: '18:00'
  },
  [FitTimesList.evening]: {
    startTime: '18:00',
    endTime: '24:00'
  }
};

const getTimeFilter = ({ time }: { time?: FitTimesList }) => {
  if (!time) {
    return {};
  }

  return TIME_FILTER_START_END_TIME_MAP[time] || {};
};

const PsychologistListing = () => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParam = location.search;
  const { filter } = useParams<{ filter: string }>();
  const getPathFilter = filter?.split('-') || [];

  const getMentalConcernFilter: MentalHealthList[] = Object.values(MentalHealthList).filter((obj) =>
    getPathFilter.includes(obj)
  ) as MentalHealthList[];

  const {
    gender,
    day,
    time,
    deliveryMode,
    ageRange,
    location: filterLocation
  }: PsychologistFitFilter & { ageRange?: string; location?: string } = queryString.parse(queryParam);

  const ageRangeQueryFilter = Object.values(AgeRangeList).includes(ageRange as AgeRangeList)
    ? (ageRange as AgeRangeList)
    : undefined;

  const [selectedMentalHealthConcern, setSelectedMentalHealthConcern] =
    useState<MentalHealthList[]>(getMentalConcernFilter);
  const [selectedLocation, setSelectedLocation] = useState<LocationList[]>(
    (filterLocation?.split(',') as LocationList[]) || []
  );
  const [selectedFitFilter, setSelectedFitFilter] = useState<PsychologistFitFilter>({
    gender: gender || undefined,
    day: day || undefined,
    time: time || undefined,
    deliveryMode: deliveryMode || undefined
  });

  const [showWaitlistForm, setShowWaitlistForm] = useState(false);

  useEffect(() => {
    // Listen to path change
    setSelectedMentalHealthConcern(getMentalConcernFilter);
    setSelectedLocation((filterLocation?.split(',') as LocationList[]) || []);
    setSelectedFitFilter({
      gender: gender || undefined,
      day: day || undefined,
      time: time || undefined,
      deliveryMode: deliveryMode || undefined
    });
    // will hit infinity loop if we listen to filter value change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const { PRACTITIONER } = useSelectRoutesGenerator();

  const { clientBookingRule, isClientBookingRuleLoading } = useFetchClientBookingRule();
  const isNewClient = clientBookingRule === BookingRuleType.New || !isAuthenticated;

  const filterQuery = useMemo(() => {
    const { day, ...selectedFitFilterRest } = selectedFitFilter;

    return {
      ...selectedFitFilterRest,
      clientMentalHealthConcern: selectedMentalHealthConcern.join(','),
      clientAgeRange: ageRangeQueryFilter,
      locations: selectedLocation.join(','),
      days: day,
      deliveryMode: selectedFitFilter.deliveryMode,
      ...getTimeFilter({ time: selectedFitFilter.time }),
      isNewClient
    };
  }, [selectedFitFilter, selectedMentalHealthConcern, ageRangeQueryFilter, selectedLocation, isNewClient]);

  const {
    psychologistList,
    isPractitionerListLoading,
    loadMorePsychologists,
    hasMorePsychologists,
    matchedSpecialisations
  } = useFetchPractitionerList({
    clientTimeZone: SelectDefaultTimezone,
    filterQuery,
    shouldFetch: !isClientBookingRuleLoading
  });

  const noResultFilterQuery = useMemo(
    () => ({
      isNewClient,
      perPage: 10
    }),
    [isNewClient]
  );

  const { psychologistList: psychologistListNoResult } = useFetchPractitionerList({
    clientTimeZone: SelectDefaultTimezone,
    filterQuery: noResultFilterQuery
  });

  const handleNavigatePath = ({
    pathFilters,
    queryFilters
  }: {
    pathFilters: string[];
    queryFilters?: LocationQueryFilters;
  }) => {
    const generateFilterPath = pathFilters.filter((obj) => obj !== undefined).join('-');
    const newPath = generatePath(PRACTITIONER.LISTING_WITH_FILTER, {
      filter: generateFilterPath
    });

    const generateQueryParam = queryString.stringify(
      queryFilters || {
        ...selectedFitFilter,
        ...(selectedLocation.length && { location: selectedLocation }),
        ageRange: ageRangeQueryFilter
      },
      { sort: false, arrayFormat: 'comma' }
    );
    navigate(`${newPath}${generateQueryParam ? `?${generateQueryParam}` : ''}`);
  };

  const handleMentalHealthChange = (value: listInterface) => {
    const checkIfDuplicate = selectedMentalHealthConcern.some((mhObj) => mhObj === value.id);

    const newCollection: MentalHealthList[] = checkIfDuplicate
      ? selectedMentalHealthConcern.filter((mhObj) => mhObj !== value.id)
      : [...selectedMentalHealthConcern, value.id as MentalHealthList];

    setSelectedMentalHealthConcern(() => newCollection);
    handleNavigatePath({ pathFilters: [...newCollection] });
  };

  const handleLocationChange = (value: listInterface) => {
    const isSelected = selectedLocation.some((locationObj) => locationObj === value.id);

    const newLocationValue = isSelected
      ? selectedLocation.filter((locationObj) => locationObj !== value.id)
      : [...selectedLocation, value.id as LocationList];

    setSelectedLocation(newLocationValue);
    handleNavigatePath({
      pathFilters: [...selectedMentalHealthConcern],
      queryFilters: {
        ...selectedFitFilter,
        ageRange: ageRangeQueryFilter,
        location: newLocationValue
      }
    });
  };

  const handleFitResetValue = () => {
    const defaultValue: LocationQueryFilters = {
      gender: undefined,
      day: undefined,
      time: undefined,
      deliveryMode: undefined,
      ageRange: ageRangeQueryFilter,
      location: selectedLocation
    };
    setSelectedFitFilter(defaultValue);
    handleNavigatePath({
      pathFilters: [...selectedMentalHealthConcern],
      queryFilters: defaultValue
    });
  };

  const handleChangeFit = (fitValue: PsychologistFitFilter) => {
    setSelectedFitFilter(fitValue);
    handleNavigatePath({
      pathFilters: [...selectedMentalHealthConcern],
      queryFilters: { ...fitValue, ageRange: ageRangeQueryFilter, location: selectedLocation }
    });
  };

  return (
    <SelectHelmetWrapper title={'Psychologist Newcastle | Private Therapy & Counselling | Select Psychology'}>
      <div className="select-theme">
        <div className={styles.headerContainer}>
          <SelectHeader className={styles.header} withPadding withMenu />
          <ListingFilter
            selectedLocation={selectedLocation}
            selectedMentalHealthConcern={selectedMentalHealthConcern}
            onChangeSelectedLocation={handleLocationChange}
            onChangeMentalHealthConcern={handleMentalHealthChange}
          />
        </div>
        <FitFilter
          selectedFitValue={selectedFitFilter}
          onChangeFitValue={handleChangeFit}
          handleFitResetValue={handleFitResetValue}
        />
        {isPractitionerListLoading || isClientBookingRuleLoading ? (
          <div className={styles.container}>
            {[...Array(4)].map((_, i) => (
              <div key={i} className={styles.contentWrapper}>
                <SelectContentLayout>
                  <div className={styles.content}>
                    <div className={styles.loadingWrapper}>
                      <div className={styles.profileWrapper}>
                        <Skeleton.Avatar active size={70} className={styles.headerLoading} />
                        <div className={styles.bonaFidesLoadingWrapper}>
                          <Skeleton.Button active className={styles.bonaFidesLoading} />
                          <Skeleton.Button active className={styles.bonaFidesLoading} />
                        </div>
                      </div>
                      <div className={styles.detailsContainer}>
                        <Skeleton active />
                      </div>
                    </div>
                  </div>
                </SelectContentLayout>
              </div>
            ))}
          </div>
        ) : psychologistList.length > 0 ? (
          <PsychologistList
            psychologistList={psychologistList}
            matchedSpecialisations={matchedSpecialisations}
            selectedFitFilter={selectedFitFilter}
            selectedMentalHealthConcern={selectedMentalHealthConcern}
            hasMorePsychologists={hasMorePsychologists}
            isNewClient={isNewClient}
            isReversedBackground={false}
            loadMorePsychologists={loadMorePsychologists}
            onClickJoinWaitlist={() => setShowWaitlistForm(true)}
          />
        ) : (
          <div className={styles.container}>
            <div className={styles.contentWrapper}>
              <SelectContentLayout>
                <div className={styles.noResultWrapper}>
                  <div className={styles.noResultTitle}>There are no exact matches</div>
                  <div className={styles.noResultDesc}>
                    While we couldn't find an exact match based on your criteria, the therapists below may still be an
                    excellent fit for your needs. If you would like more guidance, please{' '}
                    <a href={SelectContactUrl}>contact us</a> and we will be happy to help.
                  </div>
                </div>
              </SelectContentLayout>
            </div>
            {psychologistListNoResult.length > 0 && (
              <div className={styles.contentWrapper}>
                <div className={styles.recommendWrapper}>
                  <div className={styles.trustLabel}>
                    <span className={styles.label}>These trusted psychologists could be a good fit for you</span>
                  </div>
                </div>
                <PsychologistList
                  psychologistList={psychologistListNoResult}
                  matchedSpecialisations={matchedSpecialisations}
                  selectedFitFilter={selectedFitFilter}
                  selectedMentalHealthConcern={selectedMentalHealthConcern}
                  hasMorePsychologists={hasMorePsychologists}
                  isNewClient={isNewClient}
                  isReversedBackground
                  loadMorePsychologists={loadMorePsychologists}
                  onClickJoinWaitlist={() => setShowWaitlistForm(true)}
                />
              </div>
            )}
          </div>
        )}
        <SelectFooter />

        <Modal
          width={700}
          visible={showWaitlistForm}
          onCancel={() => {
            setShowWaitlistForm(false);
          }}
          bodyStyle={{ padding: '40px 80px', top: 50 }}
          footer={null}
          destroyOnClose
        >
          <JoinWaitlistForm
            onComplete={() => {
              setShowWaitlistForm(false);
            }}
            accountId={psychologistListNoResult[0]?.accountId}
          />
        </Modal>
      </div>
    </SelectHelmetWrapper>
  );
};

export default PsychologistListing;
