import { useMemo } from 'react';
import Button from 'components/Button/Button';
import styles from './AppointmentList.module.scss';
import Appointment from './Appointment/Appointment';
import classNames from 'classnames';
import LoadingCircle from 'components/LoadingCircle/LoadingCircle';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { AppointmentStatusOption } from 'helm/interfaces/Appointment/Appointment';
import {
  IS_CAW_APP,
  IS_EASE_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';
import { generatePath, Link } from 'react-router-dom';
import { useGetAccessToken } from 'utils/hooks/token';
import { useFetchAppointments, useFetchRescheduleRules } from 'utils/hooks/appointment';
import classnames from 'classnames';
import ButtonSH from '../../SomeoneHealth/components/ButtonSH/ButtonSH';
import ButtonCaW from '../../CaW/components/ButtonCaW/ButtonCaW';
import { useGetAttachedClinicianDetails } from '../../redux/endpoints/clinicianProfileServices/getClientDetails';
import ButtonEase from 'Ease/components/ButtonEase/ButtonEase';
import ButtonRecharge from 'Recharge/components/ButtonRecharge/ButtonRecharge';
import ButtonSelect from 'Select/components/ButtonSelect/ButtonSelect';
import { config } from 'config/config';
import { configNZ } from 'config/configNZ';

export const AttendedTags = [AppointmentStatusOption.Attended];
export const NotAttendedTags = [
  AppointmentStatusOption.DidNotAttend,
  AppointmentStatusOption.ClientDidNotAttend,
  AppointmentStatusOption.ClinicianDidNotAttend
];

export const completedTags = [...AttendedTags, ...NotAttendedTags];

export const massageDateTime = ({
  date,
  startTime,
  endTime,
  clinicianTimeZone,
  clientTimeZone
}: {
  date: string;
  startTime: string;
  endTime: string;
  clinicianTimeZone: string;
  clientTimeZone: string;
}) => {
  const clinicianStartTimeZone = momentTz.tz(`${date} ${startTime}`, clinicianTimeZone);
  const clientStartTimeZone = momentTz.tz(clinicianStartTimeZone, clientTimeZone);
  const clinicianEndTimeZone = momentTz.tz(`${date} ${endTime}`, clinicianTimeZone);
  const clientEndTimeZone = momentTz.tz(clinicianEndTimeZone, clientTimeZone);
  return {
    date: clientStartTimeZone.format('YYYY-MM-DD'),
    startTime: clientStartTimeZone.format('HH:mm'),
    endTime: clientEndTimeZone.format('HH:mm')
  };
};

interface AppointmentListProps {
  isPatientProfileLoading: boolean;
  clientProfileTimeZone?: string;
  newAppointmentPath?: string;
  className?: string;
}

const AppointmentList = ({
  isPatientProfileLoading,
  clientProfileTimeZone,
  newAppointmentPath,
  className
}: AppointmentListProps) => {
  const { token } = useGetAccessToken();
  const { attachedClinicianDetail } = useGetAttachedClinicianDetails();
  const { rescheduleRules } = useFetchRescheduleRules(token);
  const { appointments, isAppointmentsLoading, refetchAppointments } = useFetchAppointments(token);

  const timeZone = useMemo(() => {
    const isNZClient = attachedClinicianDetail?.practice?.country === configNZ.countryCode;
    const clientTimeZone = isNZClient ? configNZ.defaultTimezone : clientProfileTimeZone || config.defaultTimezone;
    const clinicianTimeZone = isNZClient
      ? configNZ.defaultTimezone
      : attachedClinicianDetail?.clinician?.accountSettings?.timezone || config.defaultTimezone;

    return { clientTimeZone, clinicianTimeZone };
  }, [attachedClinicianDetail, clientProfileTimeZone]);

  const massagedAppointment = useMemo(
    () =>
      timeZone.clientTimeZone
        ? appointments.map((appointment) => ({
            ...appointment,
            ...massageDateTime({
              date: appointment.date,
              startTime: appointment.startTime,
              endTime: appointment.endTime,
              clientTimeZone: timeZone.clientTimeZone,
              clinicianTimeZone: timeZone.clinicianTimeZone
            })
          }))
        : appointments,
    [appointments, timeZone]
  );

  const upcomingAppointments = useMemo(() => {
    return massagedAppointment.filter((appointment) => {
      const apptTime = moment(`${appointment.date} ${appointment.startTime}`);
      const now = moment();
      return (
        apptTime.diff(now) > 0 &&
        !appointment.markedStatus?.some((obj) => completedTags.some((tagsObj) => tagsObj === obj))
      );
    });
  }, [massagedAppointment]);

  const completedAppointments = useMemo(() => {
    return massagedAppointment.filter((appointment) => {
      const apptTime = moment(`${appointment.date} ${appointment.endTime}`);
      const now = moment();
      return (
        apptTime.diff(now) <= 0 ||
        appointment.markedStatus?.some((obj) => completedTags.some((tagsObj) => tagsObj === obj))
      );
    });
  }, [massagedAppointment]);

  return (
    <div className={classnames(styles.card, className)}>
      {isAppointmentsLoading || isPatientProfileLoading ? (
        <div className={styles.loaderContainer}>
          <LoadingCircle />
        </div>
      ) : (
        <>
          <div className={styles.header}>
            <div className={styles.left}>
              <span className={styles.headerText}>Upcoming Appointments</span>
              <span className={styles.numberCircle}>{upcomingAppointments.length}</span>
            </div>
            <div className={styles.right}>
              {newAppointmentPath && attachedClinicianDetail && attachedClinicianDetail.clinician && (
                <>
                  {IS_CAW_APP ? (
                    <ButtonCaW
                      to={generatePath(newAppointmentPath, {
                        clinicianId: attachedClinicianDetail.clinician._id
                      })}
                      icon={'event'}
                    >
                      Book New Appointment
                    </ButtonCaW>
                  ) : IS_SOMEONE_HEALTH_APP ? (
                    <ButtonSH
                      to={`${newAppointmentPath}?id=${attachedClinicianDetail.clinician.slugUrl}`}
                      icon={'event'}
                      isExternalLink
                    >
                      Book New Appointment
                    </ButtonSH>
                  ) : IS_EASE_APP ? (
                    <ButtonEase
                      to={generatePath(newAppointmentPath, {
                        clinicianId: attachedClinicianDetail.clinician._id
                      })}
                      icon={'event'}
                    >
                      BOOK NEW APPOINTMENT
                    </ButtonEase>
                  ) : IS_RECHARGE_APP ? (
                    <ButtonRecharge
                      to={generatePath(newAppointmentPath, {
                        clinicianId: attachedClinicianDetail.clinician._id
                      })}
                      icon={'event'}
                    >
                      Book New Appointment
                    </ButtonRecharge>
                  ) : IS_SELECT_APP ? (
                    <ButtonSelect
                      to={generatePath(newAppointmentPath, {
                        clinicianId: attachedClinicianDetail.clinician._id
                      })}
                      icon={'event'}
                    >
                      Book New Appointment
                    </ButtonSelect>
                  ) : (
                    <Link
                      to={generatePath(newAppointmentPath, {
                        clinicianId: attachedClinicianDetail.clinician._id
                      })}
                    >
                      <Button className={styles.button} variant="primary">
                        <span className={classNames('material-icons-outlined', styles.buttonIcon)}>event</span>
                        <span className={styles.buttonText}>Book New Appointment</span>
                      </Button>
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
          {upcomingAppointments.length > 0 && (
            <div className={styles.body}>
              {upcomingAppointments.map((appointment, index) => (
                <Appointment
                  key={index}
                  appointment={appointment}
                  rescheduleRules={rescheduleRules}
                  reload={refetchAppointments}
                  withNextLabel={index === 0}
                  clientTimeZone={timeZone.clientTimeZone}
                  clinicianTimeZone={timeZone.clinicianTimeZone}
                />
              ))}
            </div>
          )}
          {completedAppointments.length > 0 && (
            <>
              <div className={styles.header}>
                <div className={styles.left}>
                  <span className={styles.headerText}>Past Appointments</span>
                  <span className={styles.numberCircle}>{completedAppointments.length}</span>
                </div>
              </div>
              <div>
                {completedAppointments.map((appointment, index) => (
                  <Appointment
                    key={index}
                    completed
                    appointment={appointment}
                    withTagsIcon
                    clientTimeZone={timeZone.clientTimeZone}
                    clinicianTimeZone={timeZone.clinicianTimeZone}
                  />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AppointmentList;
