import { notification } from 'antd';
import Loading from 'components/Loading/Loading';
import { CommunicationPreference } from 'interfaces/Clients/clientsRecord';
import moment from 'moment';
import { ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import OCD from 'SomeoneHealth/assets/images/OCD.png';
import SHGPReferral from 'SomeoneHealth/components/SHGPReferral/SHGPReferral';
import { getAuMobile, initialCustomer } from 'SomeoneHealth/components/SHCreateProfileForm/constants';
import SHCreateProfileForm from 'SomeoneHealth/components/SHCreateProfileForm/SHCreateProfileForm';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { useFetchPublicPracticeProfile } from 'utils/hooks/accounts';
import { checkEmailMobileNumberInvite } from 'utils/http/ClinicianProfileService/Accounts/accounts';
import {
  postPatientSignUpWithInvitation,
  SHSignUpWithInviteRequestBody
} from 'utils/http/PatientProfileService/Patient/patient';
import { storeUserSession, UserSessionStorage } from '../SignUp/helper/userSession';
import styles from '../SignUp/SignUp.module.scss';
import ErrorMessageWithHelpLink from './components/ErrorMessageWithHelpLink/ErrorMessageWithHelpLink';
import OTPModal from './components/OTPModal/OTPModal';

const OTP_DEFAULT_VALUE: [string, string, string, string, string, string] = ['', '', '', '', '', ''];

interface CheckEmailMobileNumberInviteResponse {
  invited: boolean;
  emailInvited: boolean;
  used: boolean;
  clientRecordId?: string;
}

const SignUpWithInvite = () => {
  const navigate = useNavigate();
  const { SIGNUP_INVITE } = useSomeoneHealthRoutesGenerator();

  const [otp, setOtp] = useState<typeof OTP_DEFAULT_VALUE>(OTP_DEFAULT_VALUE);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [emailErrorMsg, setEmailErrorMsg] = useState<ReactNode>();
  const [mobileNumberErrorMsg, setMobileNumberErrorMsg] = useState<ReactNode>();
  const [signUpValues, setSignUpValues] = useState({} as typeof initialCustomer);
  const [isOTPModalVisible, setIsOTPModalVisible] = useState(false);
  const [otpError, setOtpError] = useState('');

  const handleChangeOTP = (newOtp: typeof otp) => {
    setOtp(newOtp);
    if (newOtp.length === 6) {
      setOtpError('');
    }
  };

  const { practiceProfile, isPracticeProfileLoading } = useFetchPublicPracticeProfile('someone-health');

  const onSubmitHandle = async (values: typeof initialCustomer) => {
    setSignUpValues(values);

    try {
      setIsSubmitting(true);
      const formattedMobileNumber = getAuMobile(values.mobileNumber);
      const response = await checkEmailMobileNumberInvite(practiceProfile?.accountId || '', {
        email: values.email,
        mobileNumber: formattedMobileNumber,
        shouldSendOtp: true
      });
      const validationResult: CheckEmailMobileNumberInviteResponse = await response.json();
      setIsSubmitting(false);
      if (!validationResult.invited) {
        if (!validationResult.emailInvited) {
          setEmailErrorMsg(
            <ErrorMessageWithHelpLink
              isEmail
              errorMessage="The email address entered isn't the same as where we sent your invite. Please check for typos and try again. If you would like to update your email address, email"
            />
          );
        } else {
          setMobileNumberErrorMsg(
            <ErrorMessageWithHelpLink errorMessage="Mobile number not recognized. Please check for typos and try again." />
          );
        }
        return;
      }
      if (validationResult.used) {
        setEmailErrorMsg(<ErrorMessageWithHelpLink errorMessage="Email address is already used." />);
        return;
      }
      setIsOTPModalVisible(true);
    } catch (ex) {
      setIsSubmitting(false);
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to sign up account'
      });
    }
  };

  const onSubmitOTPHandle = async () => {
    const { firstName, lastName, dateOfBirth, email, mobileNumber, password, receiveSMS } = signUpValues;
    const communicationPreference = receiveSMS ? CommunicationPreference.NoPreference : CommunicationPreference.Email;
    const dob = moment(dateOfBirth).format('DD/MM/YYYY');

    const formattedMobileNumber = getAuMobile(mobileNumber);
    const payload: SHSignUpWithInviteRequestBody = {
      data: {
        firstName,
        lastName,
        dateOfBirth: dob,
        email,
        mobileNumber: formattedMobileNumber,
        password,
        communicationPreference
      },
      smsOtp: otp.join('')
    };
    try {
      setIsSubmitting(true);
      const callPatientSignUp = await postPatientSignUpWithInvitation(practiceProfile?.accountId || '', payload);
      if (callPatientSignUp.statusCode === 200) {
        setIsOTPModalVisible(false);
        setIsSubmitting(false);

        const { clientRecord, authToken }: UserSessionStorage = await callPatientSignUp.json();
        storeUserSession({ clientRecord, authToken, firstName }, dateOfBirth);
        navigate(SIGNUP_INVITE.ADD_PAYMENT);
      } else {
        setIsSubmitting(false);
        setOtpError('true');
      }
    } catch (ex) {
      setIsSubmitting(false);
      console.error(ex);
      notification.error({
        message: 'Something went wrong while trying to sign up account'
      });
    }
  };

  const handleResendOtp = async () => {
    setOtp(OTP_DEFAULT_VALUE);
    setOtpError('');
    await onSubmitHandle(signUpValues);
  };

  return isPracticeProfileLoading ? (
    <div className={styles.loadingContainer}>
      <Loading />
    </div>
  ) : (
    <SomeoneHealthHelmetWrapper title={'someone.health - Sign up with invite'}>
      {isPracticeProfileLoading ? (
        <Loading />
      ) : (
        <SomeoneHealthContentLayout>
          <SomeoneHealthHeader pinkLogo withPadding classNames={styles.header} loginTextClass={styles.loginTextClass} />
          <div className={styles.container}>
            <div className={styles.leftContent}>
              <SHCreateProfileForm
                isSubmitting={isSubmitting}
                emailErrorMsg={emailErrorMsg}
                mobileNumberErrorMsg={mobileNumberErrorMsg}
                setEmailErrorMsg={setEmailErrorMsg}
                setMobileNumberErrorMsg={setMobileNumberErrorMsg}
                onSubmit={onSubmitHandle}
                customAgeCheck={13}
              />
            </div>
            <div className={styles.rightContent}>
              <SHGPReferral />
              <div className={styles.topNotch}>
                <img src={OCD} alt={'Someone Health top notch'} />
                <div className={styles.topNotchTitle}>Top-notch privacy and security</div>
                <div className={styles.topNotchDesc}>
                  <b>someone.health</b> was built to exceed all regulatory requirements for privacy and security. All
                  data is stored within Australia and encrypted both at-rest and in-transit, with strict access
                  controls.
                </div>
              </div>
            </div>
          </div>
        </SomeoneHealthContentLayout>
      )}
      <TacklitFooter />
      <OTPModal
        otp={otp}
        otpError={otpError}
        isSubmitting={isSubmitting}
        clientName={signUpValues.firstName}
        visible={isOTPModalVisible}
        onResend={handleResendOtp}
        onSubmit={onSubmitOTPHandle}
        onChangeValue={handleChangeOTP}
      />
    </SomeoneHealthHelmetWrapper>
  );
};

export default SignUpWithInvite;
