import { notification } from 'antd';
import Loading from 'components/Loading/Loading';
import { useState } from 'react';
import ButtonSH, { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import SomeoneHealthContentLayout from 'SomeoneHealth/components/SomeoneHealthContentLayout/SomeoneHealthContentLayout';
import SomeoneHealthHeader from 'SomeoneHealth/components/SomeoneHealthHeader/SomeoneHealthHeader';
import SomeoneHealthHelmetWrapper from 'SomeoneHealth/components/SomeoneHealthHelmetWrapper/SomeoneHealthHelmetWrapper';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { postStripePaymentMethod } from 'utils/http/BillingService/stripe';
import { retrieveUserSession } from '../SignUp/helper/userSession';
import styles from './AddPayment.module.scss';
import TacklitFooter from 'components/TacklitFooter/TacklitFooter';

const AddPayment = () => {
  const signedUpUser = retrieveUserSession();
  const { SIGNUP_INVITE } = useSomeoneHealthRoutesGenerator();

  const [buttonStatus, setButtonStatus] = useState<ButtonStatusType>('');

  const onAddPaymentDetails = async () => {
    setButtonStatus('active');
    try {
      const response = await postStripePaymentMethod({
        token: signedUpUser.authToken || '',
        payload: {
          returnUrl: `${window.location.origin}${SIGNUP_INVITE.WELCOME_INVITE}`,
          cancelUrl: `${window.location.origin}${SIGNUP_INVITE.ADD_PAYMENT}`
        }
      });
      setButtonStatus('finished');
      window.location.href = await response.text();
    } catch (error) {
      console.error(error);
      notification.error({ message: 'Something went wrong while trying to generate the link. Please try again.' });
    } finally {
      setTimeout(() => {
        setButtonStatus('');
      }, 1000);
    }
  };

  return (
    <SomeoneHealthHelmetWrapper title={'someone.health - Add payment'}>
      <div className={styles.pageContainer}>
        <SomeoneHealthHeader pinkLogo noLogin classNames={styles.header} />
        {!signedUpUser && <Loading />}
        <SomeoneHealthContentLayout className={styles.container}>
          <div className={styles.title}>Add payment details</div>
          <div className={styles.content}>
            <b>Welcome {signedUpUser.firstName || signedUpUser.clientRecord?.clientProfiles[0].firstName}</b>
            <br />
            <br />
            The next step to create your someone.health profile is to securely store your preferred payment method.
            <br />
            <br />
            As a reminder, our pricing is changing from 5 April 2023 to the following:
            <br />
            <br />
            <b>Your first two sessions this year are fully bulk billed</b>
            <br />
            There are no fees to attend your first two sessions in 2023 with someone.health if you have a valid GP
            referral. This is for your first two sessions with any of our psychologists, so the counter doesn't reset if
            you start seeing a different psychologist.
            <br />
            <br />
            <b>After that it's just $40, after the Medicare rebate</b>
            <br />
            For session 3 to 10 you'll need to pay the full amount, but will still get a Medicare rebate. This means the
            amount you'll pay after the Medicare rebate is just $40, plus any bank processing fees.
            <br />
            <br />
            3 days prior to your appointment your saved payment method will be charged $40.
            <br />
            <br />
            At the time of your appointment you'll also need to pay the remaining amount of either $89.65 or $131.65,
            but we'll process your Medicare rebate immediately and so you'll be refunded either $89.65 or $131.65)
            direct to your bank account from Medicare. This is the same experience as when you see a GP that doesn't
            bulk bill.
            <br />
            <br />
            On the next page you'll be asked to save your payment details which will allow you to continue attending
            appointments. We require all clients to have an active payment method saved in order to attend any
            appointment.
            <br />
            <br />
            <div>
              <ButtonSH className={styles.button} onClick={onAddPaymentDetails} status={buttonStatus}>
                Securely save my payment details
              </ButtonSH>
            </div>
          </div>
        </SomeoneHealthContentLayout>
        <TacklitFooter />
      </div>
    </SomeoneHealthHelmetWrapper>
  );
};

export default AddPayment;
