import { Tooltip } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import momentTz from 'moment-timezone';
import { Appointment as AppointmentInterface } from 'pages/PatientAppointment/AppointmentConfirmed/AppointmentConfirmed';
import { useMemo, useState } from 'react';
import styles from './Appointment.module.scss';
import ChangeAppointmentModal from './ChangeAppointmentModal/ChangeAppointmentModal';
import InvoiceModal from './InvoiceModal/InvoiceModal';
import { AttendedTags, completedTags, NotAttendedTags } from '../AppointmentList';
import { DeliveryType, RescheduleRules } from 'utils/hooks/appointment';
import { ClientRescheduleRequestStatus } from 'helm/interfaces/Appointment/ClientRescheduleRequestStatus';
import { linkify } from 'utils/general';
import {
  IS_EASE_APP,
  IS_RECHARGE_APP,
  IS_SELECT_APP,
  IS_SOMEONE_HEALTH_APP
} from 'utils/hooks/AccountInfo/clientDetails';

const DISPLAY_TIME_FORMAT = 'h:mmA on dddd DD MMMM YYYY';

interface TagsInfo {
  icon: string;
  label: string;
}

interface AppointmentProps {
  appointment: AppointmentInterface;
  rescheduleRules?: RescheduleRules;
  completed?: boolean;
  reload?: () => void;
  withTagsIcon?: boolean;
  withNextLabel?: boolean;
  clientTimeZone?: string;
  clinicianTimeZone: string;
}

const Appointment = ({
  appointment,
  rescheduleRules,
  completed = false,
  reload,
  withTagsIcon,
  withNextLabel,
  clientTimeZone,
  clinicianTimeZone
}: AppointmentProps) => {
  const [expanded, setExpanded] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showingInvoiceId, setShowingInvoiceId] = useState<string | undefined>();

  const startTime = moment(`${appointment.date} ${appointment.startTime}`);
  const endTime = moment(`${appointment.date} ${appointment.endTime}`);
  const howToConnect = useMemo(() => {
    if (appointment.deliveryType === DeliveryType.FaceToFace) {
      return `Face to face ${appointment.faceToFaceLocation ? `at ${linkify(appointment.faceToFaceLocation)}` : ''}`;
    }
    if (appointment.deliveryType === DeliveryType.PhoneCall) {
      return `Phone call - ${linkify(appointment.phoneCallInstructions) || ''}`;
    }
    if (appointment.deliveryType === DeliveryType.PhoneCallDialClient) {
      return `Phone call - ${appointment.phoneCallDialClientInstructions || ''}`;
    }
    if (appointment.deliveryType === DeliveryType.VideoCall) {
      return `Video call - ${linkify(appointment.videoCallInstructions) || ''}`;
    }
    if (appointment.deliveryType === DeliveryType.Other && appointment.otherInstructions) {
      return `${appointment.otherInstructions.title} - ${linkify(appointment.otherInstructions.instructions) || ''}`;
    }
    return '';
  }, [appointment]);

  const onCloseDialog = (reloadData: boolean) => {
    setShowEditModal(false);
    if (reloadData) {
      setTimeout(() => {
        reload?.();
      }, 500);
    }
  };

  const includeAttendedTag =
    withTagsIcon && appointment.markedStatus?.some((obj) => AttendedTags.some((tagsObj) => tagsObj === obj));
  const includeDidNotAttendedTags =
    withTagsIcon && appointment.markedStatus?.some((obj) => NotAttendedTags.some((tagsObj) => tagsObj === obj));
  const noCompleteTags =
    withTagsIcon && !appointment.markedStatus?.some((obj) => completedTags.some((tagsObj) => tagsObj === obj));

  let tagInfo: TagsInfo;
  if (includeAttendedTag) {
    tagInfo = {
      icon: 'event_available',
      label: 'Attended'
    };
  } else if (includeDidNotAttendedTags) {
    tagInfo = {
      icon: 'event_busy',
      label: 'Did not attended'
    };
  } else if (noCompleteTags) {
    tagInfo = {
      icon: 'warning_amber',
      label: 'No attendance taken yet'
    };
  } else {
    tagInfo = {
      icon: 'event',
      label: ''
    };
  }

  return (
    <div className={classNames(styles.container, expanded && styles.active)}>
      <div className={styles.row}>
        <div
          className={classNames(
            styles.iconContainer,
            completed && styles.completed,
            includeAttendedTag
              ? styles.attended
              : !noCompleteTags
              ? styles.didNotAttended
              : !IS_SOMEONE_HEALTH_APP
              ? styles.warning
              : ''
          )}
        >
          <Tooltip title={tagInfo.label}>
            <span className={classNames('material-icons-outlined', styles.icon)}>{tagInfo.icon}</span>
          </Tooltip>
        </div>
        <div className={styles.textContainer}>
          {withNextLabel && <div className={styles.label}>NEXT</div>}
          <div className={styles.text}>
            <span>
              {endTime.diff(startTime, 'm')} minutes, {appointment.sessionTypeName} booked for{' '}
            </span>
            {IS_SOMEONE_HEALTH_APP ? (
              startTime.format(DISPLAY_TIME_FORMAT)
            ) : (
              <strong>{startTime.format(DISPLAY_TIME_FORMAT)}</strong>
            )}
          </div>
          {appointment.clientRescheduleDetails &&
            appointment.clientRescheduleDetails.status &&
            appointment.clientRescheduleDetails.date &&
            appointment.clientRescheduleDetails.startTime &&
            appointment.clientRescheduleDetails.endTime &&
            (appointment.clientRescheduleDetails.status === ClientRescheduleRequestStatus.Rejected ? (
              <div className={styles.requestContainer}>
                <div className={styles.requestText}>
                  <span>
                    Reschedule request to{' '}
                    {clientTimeZone
                      ? momentTz
                          .tz(
                            momentTz.tz(
                              `${appointment.clientRescheduleDetails.date} ${appointment.clientRescheduleDetails.startTime}`,
                              clinicianTimeZone
                            ),
                            clientTimeZone
                          )
                          .format(DISPLAY_TIME_FORMAT)
                      : moment(
                          `${appointment.clientRescheduleDetails.date} ${appointment.clientRescheduleDetails.startTime}`
                        ).format(DISPLAY_TIME_FORMAT)}{' '}
                    <span className={styles.rejected}>REJECTED</span>.
                  </span>
                </div>
                {appointment.clientRescheduleDetails.note && (
                  <>
                    <div className={styles.notes}>Notes from your practitioner:</div>
                    <div className={styles.notes}>{appointment.clientRescheduleDetails.note}</div>
                  </>
                )}
              </div>
            ) : (
              <div className={styles.requestText}>
                <span>
                  Reschedule request to{' '}
                  {clientTimeZone
                    ? momentTz
                        .tz(
                          momentTz.tz(
                            `${appointment.clientRescheduleDetails.date} ${appointment.clientRescheduleDetails.startTime}`,
                            clinicianTimeZone
                          ),
                          clientTimeZone
                        )
                        .format(DISPLAY_TIME_FORMAT)
                    : moment(
                        `${appointment.clientRescheduleDetails.date} ${appointment.clientRescheduleDetails.startTime}`
                      ).format(DISPLAY_TIME_FORMAT)}{' '}
                  sent.
                </span>
              </div>
            ))}
        </div>
        <div className={styles.actionsContainer}>
          {!completed && (
            <button className={styles.button} onClick={() => setShowEditModal(true)}>
              <span className={classNames('material-icons-outlined', styles.icon)}>edit_calendar</span>
            </button>
          )}
          {appointment.invoices?.map((invoice, index) => (
            <div key={index}>
              <button className={styles.button} onClick={() => setShowingInvoiceId(invoice._id)}>
                <span className={classNames('material-icons-outlined', styles.icon)}>receipt</span>
              </button>
              <InvoiceModal
                invoiceId={invoice._id}
                visible={showingInvoiceId === invoice._id}
                modalWrapperClassName={classNames(
                  IS_EASE_APP && 'ease-theme',
                  IS_RECHARGE_APP && 'recharge-theme',
                  IS_SELECT_APP && 'select-theme',
                  IS_SOMEONE_HEALTH_APP && 's1h-client-portal-theme'
                )}
                closeDialog={() => setShowingInvoiceId(undefined)}
              />
            </div>
          ))}
          {!completed && (
            <button className={styles.button} onClick={() => setExpanded(!expanded)}>
              <span className={classNames('material-icons-outlined', styles.icon)}>
                {expanded ? 'arrow_downward' : 'arrow_forward'}
              </span>
            </button>
          )}
        </div>
      </div>
      {expanded && (
        <div className={styles.row}>
          <div className={classNames(styles.textContainer, styles.marginLeft)}>
            <div className={styles.label}>HOW TO CONNECT</div>
            <div className={styles.text} dangerouslySetInnerHTML={{ __html: howToConnect }} />
          </div>
        </div>
      )}
      {showEditModal && (
        <ChangeAppointmentModal
          rescheduleRules={rescheduleRules}
          appointment={appointment}
          closeDialog={onCloseDialog}
          clientTimeZone={clientTimeZone}
          clinicianTimeZone={clinicianTimeZone}
          modalWrapperClassName={classNames(
            IS_EASE_APP && 'ease-theme',
            IS_RECHARGE_APP && 'recharge-theme',
            IS_SELECT_APP && 'select-theme',
            IS_SOMEONE_HEALTH_APP && 's1h-client-portal-theme'
          )}
        />
      )}
    </div>
  );
};

export default Appointment;
