import { isDevelopmentENV } from 'utils/featureToggle/DevelopmentToggle';
import { someoneHealthEnvironment } from '../SomeoneHealthEnv/SomeoneHealthEnv';

const IS_DEVELOPMENT = isDevelopmentENV();

export const useSomeoneHealthPractitionersUrl = () => {
  const { SomeoneHealthFindPsychologistURLDev } = someoneHealthEnvironment();
  return SomeoneHealthFindPsychologistURLDev;
};

export const useSomeoneHealthPractitionerUrl = ({
  noParams,
  clinicianIdOrSlugUrl
}: {
  noParams?: boolean;
  clinicianIdOrSlugUrl?: string;
}) => {
  const { SomeoneHealthFindPsychologistURLDev, SomeoneHealthFindPsychologistURLProd } = someoneHealthEnvironment();
  const practitionerListingUrl = IS_DEVELOPMENT
    ? SomeoneHealthFindPsychologistURLDev
    : SomeoneHealthFindPsychologistURLProd;

  const practitionerDetails = `${practitionerListingUrl}/details`;

  if (noParams) {
    return practitionerDetails;
  } else if (clinicianIdOrSlugUrl) {
    return `${practitionerDetails}?id=${clinicianIdOrSlugUrl}`;
  }
  return practitionerListingUrl;
};
