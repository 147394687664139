import { clinicianDetailsInterface } from 'interfaces/Clinician/clinicianDetails';
import { BookingRulesChildProps } from 'utils/hooks/appointment';
import { AgeRangeList, GenderList, MentalHealthList } from '../../../SomeoneHealth/interfaces/mentalHealth';
import { FitDeliveryList } from '../../../SomeoneHealth/interfaces/fitFilter';

export interface timeSlotsInterface {
  startTime: string;
  endTime: string;
  startDateTime?: Date;
  endDateTime?: Date;
}

export interface availabilitiesInterface {
  date: string;
  timeSlots: timeSlotsInterface[];
}

export enum AppointmentBookingClaimType {
  BulkBill = 'bulkBill',
  Rebate = 'rebate',
  VTPHNA = 'VTPHNA',
  WISE = 'WISE'
}

export interface nextAvailability {
  appointmentTypeName: string;
  appointmentTypeId: string;
  appointmentTypeBookingRules: {
    new: BookingRulesChildProps;
    existing: BookingRulesChildProps;
  };
  appointmentTypeClaimType?: AppointmentBookingClaimType;
  availabilities: availabilitiesInterface[];
  shouldHideFromListing?: boolean;
}

export interface PractitionerDetailsInterface extends clinicianDetailsInterface {
  nextAvailabilities?: nextAvailability[];
  matchedNextAvailabilities?: nextAvailability[];
  nextAvailableTimeSlot?: {
    date: string;
    time: string;
  };
  helmControl: {
    headerPhoto: string;
    additionalPhotos: string[];
    appointmentPhoto: string;
    video?: string;
    videoPoster?: string;
    bioPhoto?: string;
    bio?: string;
    bonaFides?: {
      languages?: {
        active: boolean;
        value?: string[];
      };
      yearsOfExperience?: {
        active: boolean;
        value?: number;
      };
      style?: {
        active: boolean;
        value?: string;
      };
      qualifications?: {
        active: boolean;
        value?: string[];
      };
      medicare?: {
        active: boolean;
        value?: string;
      };
    };
    shortDescription: string;
    quote: string;
    welcomeNote: string;
    isAdvisor?: boolean;
    shouldUseCaseLoad?: boolean;
    servicesUnavailable: string[];
    clientMentalHealthConcern?: MentalHealthList[];
    clientAgeRange?: AgeRangeList[];
    clientGender?: GenderList[];
    primarySpecialisations?: string[];
    secondarySpecialisations?: string[];
    deliveryModes?: FitDeliveryList[];
    credentials?: string;
    servicesAvailable?: string[];
    styles?: string[];
    fundingMethods?: string[];
  };
}
